$(function(){
  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('ul').removeClass('open');
      $('.overlay').removeClass('open');
    } else {
      $(this).addClass('active');
      $(this).next('ul').addClass('open');
      $('.overlay').addClass('open');
    }
  });
  $('.overlay').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('.nav-sp ul').removeClass('open');
    }
  });
  if ($('body').hasClass('slick')) {
    $('.slider').slick({
      arrows: false,
      autoplay:true,
      autoplaySpeed:5000,
      dots:true,
      pauseOnHover: false,
      speed: 1005
  	});
  }

  // スマホ以外電話リンクOFF
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
  if (!isMobile) {
      $('a[href^="tel:"]').on('click', function(e) {
          e.preventDefault();
      }).addClass('disable');
  }

   $('#menu .menu, a.btn, #navbar a').on('touchstart', function(){
     $(this).addClass('hover');
   }).on('touchend', function(){
     $(this).removeClass('hover');
   });

   if ($('body').hasClass('home-movie')) {
     if ($(window).height() / $(window).width() < 0.5625) {
       $('.movie #top-movie').css({
         height:'',
         width:'100vw'
       });
     } else {
       $('.movie #top-movie').css({
         height:'100vh',
         width:''
       });
     }
   }

});



// $(window).on("load resize", mainImage);
// function mainImage(){
//   if ($('body').hasClass('works-detail')) {
//     $('.asset-header img').removeAttr('height');
//     $('.asset-header img').removeAttr('width');
//     var mainImageW = $('.asset-header img').width();
//     var mainImageH = $('.asset-header img').height();
//     var mainImageRatio = mainImageH / mainImageW;
//     var windowWidth = $(window).width();
//     var assetW = $('.asset-header').width();
//     // console.log(mainImageRatio +'　'+ mainImageW +'　'+ mainImageH +'　'+ windowWidth);
//     if (windowWidth-assetW > 0) {
//       $('.asset-header img').css({
//         'left': -(windowWidth-assetW)/2+ 'px',
//         'width':windowWidth+ 'px'
//       });
//     }
//     $('.asset-header').css('paddingTop', windowWidth*mainImageRatio+20+ 'px');
//     $('.asset-body img').addClass('img-fluid');
//   }
// }



//toTopの表示・非表示
function displayToTop(){
  var scrollTop = $(window).scrollTop();
  if (scrollTop < 300) {
    $(".to-page-top").fadeOut();
  } else {
    $(".to-page-top").fadeIn();
    $(".to-page-top a").smoothScroll({offset: -80});
  }
}
$(window).on("load scroll", displayToTop);

$(window).on("load resize", function(){
  if ($('body').hasClass('home-movie')) {
    if ($(window).height() / $(window).width() < 0.5625) {
      $('.movie #top-movie').css({
        height:'',
        width:'100vw'
      });
    } else {
      $('.movie #top-movie').css({
        height:'100vh',
        width:''
      });
    }
  }
  if ($('body').hasClass('home')) {
    if ($(window).width()<992) {
      var windowH = $(window).height();
      var featureH = $('#feature').height();
      var featurePadding = (windowH-featureH)/2;
      $('#feature').css({
        'paddingTop': featurePadding+'px',
        'paddingBottom': featurePadding+'px'
      });
    }
    if ($(window).width()>991) {
      var windowH = $(window).height();
      var featureH = 0;
          featureH = $('#feature').height();
      var featurePadding = (windowH-featureH)/2;
      $('#feature').css({
        'paddingTop': featurePadding+'px',
        'paddingBottom': featurePadding+'px'
      });
    }
  }
});
